
import UserList from '@/components/admin/UserList.vue';
import { defineComponent, PropType } from 'vue';
import { EditableUser, Student, errorPrepend } from '@/models';
import { showError } from '@/sweetalert2/templates.ts';
import { managementMixin } from '@/mixins/managementMixin';

export default defineComponent({
  name: 'StudentManagement',
  components: { UserList },
  mixins: [managementMixin],
  props: {
    students: { type: Object as PropType<Student[]>, required: true }
  },
  methods: {
    saveStudent (student: EditableUser) {
      if (student.id !== -1) {
        this.updateStudent(student);
      } else {
        this.createStudent(student);
      }
    },
    async refreshStudent (student: EditableUser) {
      if (student.id === -1) {
        this.removeValues(student);
        return;
      }
      try {
        const refreshedStudent = await Student.getById(student.id);
        this.removeItem(this.students, student);
        this.addItem(this.students, refreshedStudent);
      } catch (e) {
        await showError(this.$t("messages.no student load") + errorPrepend());
      }
    },
    async deleteStudent (student: EditableUser) {
      try {
        await Student.delete(student.id);
        this.removeItem(this.students, student);
      } catch (e) {
        await showError(this.$t("messages.error del student") + errorPrepend());
      }
    },
    async updateStudent (student: EditableUser) {
      try {
        await Student.update(student);
      } catch (e) {
        await showError(this.$t("messages.no student change") + errorPrepend());
      }
    },
    async createStudent (student: EditableUser) {
      try {
        await Student.add([student]);
        const allStudents = await Student.getAll();
        const newStudent = allStudents.find(s1 => !this.students.some(s2 => s2.id === s1.id));
        if (newStudent) {
          this.addItem(this.students, newStudent);
          newStudent.password = student.password;
          this.removeValues(student);
        }
      } catch (e) {
        await showError(this.$t("messages.no student add") + errorPrepend());
      }
    }

  },
  computed: {
    filteredStudents (): Student[] {
      const terms = this.search.toLowerCase().split(' ').filter(t => t !== '');
      return this.students.filter((s: EditableUser) =>
        terms.reduce((a: boolean, b: string) => a && (s.id === -1 || s.firstname.toLowerCase().includes(b) || s.lastname.toLowerCase().includes(b)), true));
    }
  }
});


import { defineComponent } from 'vue';
import { showError, showInfo } from '@/sweetalert2/templates.ts';
import StudentManagement from '@/components/organizer/StudentManagement.vue';
import TeacherManagement from '@/components/organizer/TeacherManagement.vue';
import { CommonError, School, Student, Teacher, errorPrepend } from '@/models';
import { downloadMixin } from '@/mixins/downloadMixin';

export default defineComponent({
  name: 'UserManagement',
  components: {
    TeacherManagement, StudentManagement
  },
  mixins: [downloadMixin],
  data () {
    return {
      passwordLength: 8,
      search: '',
      warnOnDelete: true,
      teachers: [] as Teacher[],
      students: [] as Student[],
      spinning: false
    };
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    downloadPasswords () {
      const teacherWithPw = this.teachers.filter(t => t.password && t.id !== -1).map(t => t.email + ': ' + t.password);
      const studentsWithPw = this.students.filter(s => s.password && s.id !== -1).map(s => s.email + ': ' + s.password);
      const text = teacherWithPw.concat(studentsWithPw).join('\n');

      const a = this.$refs.downloadButton as HTMLAnchorElement;
      if (text === '') {
        showError(this.$t("messages.no changes"));
        return;
      }
      this.downloadFile(text, a);
    },
    async adUpdateUsersAndGroups () {
      try {
        showInfo(School.LONG_REQUEST).then(() => null);
        this.spinning = true;
        await School.updateUsersAndGroups();
      } catch (e) {
        await showError(`${this.$t("messages.error school change") + errorPrepend()} Error: ${e.response.data.error}`);
      } finally {
        this.spinning = false;
        await this.fetchData();
      }
    },
    async fetchData () {
      try {
        this.teachers = await Teacher.getAll();
        this.teachers.push({ id: -1 } as Teacher);

        this.students = await Student.getAll();
        this.students.push({ id: -1 } as Student);
      } catch (e) {
        await showError(this.$t("messages.no data load") + errorPrepend());
      }
    }
  }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3d6cdab8")
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "h5 btn-primary btn text-white sidebar-link col-12",
  "data-toggle": "collapse",
  "aria-expanded": "true",
  "data-target": "#teachers"
}
const _hoisted_3 = {
  class: "collapse show mt-4 col-12",
  id: "teachers"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserList = _resolveComponent("UserList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t("messages.Lehrer")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_UserList, {
        warnOnDelete: _ctx.warnOnDelete,
        users: _ctx.filteredTeachers,
        passwordLength: _ctx.passwordLength,
        onSaveUser: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveTeacher($event))),
        onRefreshUser: _cache[1] || (_cache[1] = ($event: any) => (_ctx.refreshTeacher($event))),
        onDeleteUser: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteTeacher($event)))
      }, null, 8, ["warnOnDelete", "users", "passwordLength"])
    ])
  ]))
}
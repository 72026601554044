import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8b181996")
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "h5 btn-primary btn text-white sidebar-link col-12",
  "data-toggle": "collapse",
  "aria-expanded": "true",
  "data-target": "#students"
}
const _hoisted_3 = {
  class: "collapse show mt-4 col-12",
  id: "students"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserList = _resolveComponent("UserList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t("messages.Schüler")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_UserList, {
        warnOnDelete: _ctx.warnOnDelete,
        users: _ctx.filteredStudents,
        passwordLength: _ctx.passwordLength,
        onSaveUser: _ctx.saveStudent,
        onRefreshUser: _ctx.refreshStudent,
        onDeleteUser: _ctx.deleteStudent
      }, null, 8, ["warnOnDelete", "users", "passwordLength", "onSaveUser", "onRefreshUser", "onDeleteUser"])
    ])
  ]))
}

import UserList from '@/components/admin/UserList.vue';
import { EditableUser, Teacher, errorPrepend } from '@/models';
import { showError } from '@/sweetalert2/templates.ts';
import { defineComponent, PropType } from 'vue';
import { managementMixin } from '@/mixins/managementMixin';

export default defineComponent({
  name: 'TeacherManagement',
  components: { UserList },
  props: {
    teachers: { type: Object as PropType<Teacher[]>, required: true },
    search: { type: String, required: true },
    passwordLength: { type: Number, required: true },
    warnOnDelete: { type: Boolean, required: true }
  },
  mixins: [managementMixin],
  methods: {
    async refreshTeacher (teacher: EditableUser) {
      if (teacher.id === -1) {
        this.removeValues(teacher);
        return;
      }
      try {
        const refreshedTeacher = await Teacher.getById(teacher.id);
        this.removeItem(this.teachers, teacher);
        this.addItem(this.teachers, refreshedTeacher);
      } catch (e) {
        await showError(this.$t("messages.no teacher load") + errorPrepend());
      }
    },
    async deleteTeacher (teacher: EditableUser) {
      try {
        await Teacher.delete(teacher.id);
        this.removeItem(this.teachers, teacher);
      } catch (e) {
        await showError(this.$t("messages.error del teacher") + errorPrepend());
      }
    },
    saveTeacher (teacher: EditableUser) {
      if (teacher.id !== -1) {
        this.updateTeacher(teacher);
      } else {
        this.createTeacher(teacher);
      }
    },
    async createTeacher (teacher: EditableUser) {
      try {
        await Teacher.add([teacher]);
        const allTeachers = await Teacher.getAll();
        const newTeacher = allTeachers.find(t1 => !this.teachers.some(t2 => t2.id === t1.id));
        if (newTeacher) {
          this.addItem(this.teachers, newTeacher);
          newTeacher.password = teacher.password;
          this.removeValues(teacher);
        }
      } catch (e) {
        await showError(this.$t("messages.no teacher add") + errorPrepend());
      }
    },
    async updateTeacher (teacher: EditableUser) {
      try {
        await Teacher.update(teacher);
      } catch (e) {
        await showError(this.$t("messages.no change teacher") + errorPrepend());
      }
    }
  },
  computed: {
    filteredTeachers (): Teacher[] {
      const terms = this.search.toLowerCase().split(' ').filter(t => t !== '');
      return this.teachers.filter((t: EditableUser) =>
        terms.reduce((a: boolean, b: string) => a && (t.id === -1 || t.firstname.toLowerCase().includes(b) || t.lastname.toLowerCase().includes(b)), true));
    }
  }
});
